const white = '#FFFFFF';
const black = '#3b3b3b';
export const textWhite = '#585858';
export const textDark = '#f5f5f5';

export const themeLight = {
  background: white,
  color: textWhite,
  transition: 'background-color 0.1s ease',
};

export const themeDark = {
  background: black,
  color: textDark + '!important',
  transition: 'background-color 0.1s ease',
};

export const headingBreakpoint = {
  margin: '10px 0 30px 0 !important',
  letterSpacing: '0 !important',
  fontSize: '20px',
  color: '#111',
};

export const containerBreakpoint = {
  paddingTop: '60px!important',
  paddingBottom: '60px',
  paddingLeft: '40px!important',
  paddingRight: '46px!important',
};

export const projectListBreakpoint = {
  maxHeight: '500px',
  overflow: 'scroll',
  overflowX: 'hidden',
  overflowY: 'auto',
};

export const theme = {
  headingBreakpoint,
  containerBreakpoint,
  projectListBreakpoint,
  themeDark,
  themeLight,
  textDark,
  textWhite,
};

export const main = { paddingBottom: '12.5rem' };
