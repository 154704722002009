import React from 'react';
import styled from '@emotion/styled';

import { ScrollButton } from './scroll_button';

const Base = styled.footer`
  background-color: #2d2d30;
  color: #f5f5f5;
  padding: 32px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const Footer = () => {
  return (
    <>
      {/* {onMode.value && (
        <div className="float-alert">Reading mode is active.</div>
      )} */}
      <Base className="text-center">
        <ScrollButton delayInMs={0.5} scrollStepInPx={111} />
        <br /> <br />
        {/* <ScrollButton scrollStepInPx="50" delayInMs="16.66" /> */}
        <p>&copy; Repodev - Muh Ibnu Habil Hanafi</p>
      </Base>
    </>
  );
};

export default Footer;
