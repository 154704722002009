import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, Global, ThemeProvider } from '@emotion/react';

import Footer from './footer';
import NavBar from './navbar';
import SEO from './seo';
import { theme } from '../../config/theme';

const Layout = ({ children }) => {
  const [darkMode] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Global
          styles={css`
            body {
              font: 400 15px/1.8 Lato, sans-serif;
              color: #3b3b3b;
              overflow: scroll;
              overflow-x: hidden;
            }
            body.light-mode {
              ${theme.themeLight}
            }
            body.dark-mode {
              ${theme.themeDark}
              .card,
              .modal-content {
                ${darkMode ? theme.themeDark : theme.themeLight};
              }
              .listing h2 {
                color: ${darkMode && theme.textDark} !important;
              }
              .listing h2:hover {
                color: ${darkMode && '#b5e207'} !important;
              }
              .excerpt {
                color: ${darkMode && theme.textDark};
              }
              small.sub {
                color: ${darkMode && theme.textDark};
              }
              ul.list li.item:hover h2 {
                color: ${darkMode && '#abcf1b'} !important;
              }
              p > a:hover {
                text-decoration: underline;
                border-bottom: 1px solid ${darkMode && theme.textDark};
              }
              code.language-text {
                color: ${darkMode && theme.textDark} !important;
              }
              figure h1,
              h2,
              h3,
              h4,
              small {
                color: ${darkMode && theme.textDark} !important;
              }
              figure.post > a {
                color: ${darkMode && theme.textDark} !important;
              }
              figure.post > a > span {
                border-top: 0.15em solid ${darkMode && theme.textDark} !important;
                border-right: 0.15em solid ${darkMode && theme.textDark} !important;
              }
              article a {
                color: ${darkMode && '#abcf1b'};
              }
            }
            .main {
              display: block;
            }
            p > a {
              text-decoration: none !important;
              color: #3b3b3b;
            }
            p > a:hover {
              text-decoration: underline;
              border-bottom: 1px solid #2d2d30;
            }
            .container {
              padding: 80px 120px;
            }
            h3,
            h4 {
              margin: 10px 0 30px 0;
              letter-spacing: 10px;
              font-size: 20px;
              color: #111;
            }
            article h2 {
              font-size: 1.3rem;
              color: #111;
            }
            @media screen and (max-width: 320px) {
              h3,
              h4 {
                ${theme.headingBreakpoint}
              }
              h3#repodev {
                padding-top: 30px;
              }
              .container {
                ${theme.containerBreakpoint}
              }
              .projects-list {
                ${theme.projectListBreakpoint}
              }
            }
            @media screen and (max-width: 640px) {
              h3,
              h4 {
                ${theme.headingBreakpoint}
              }
              h3#repodev {
                padding-top: 30px;
              }
              .container {
                ${theme.containerBreakpoint}
              }
              .projects-list {
                ${theme.projectListBreakpoint}
              }
            }
            @media screen and (max-width: 728px) {
              h3,
              h4 {
                ${theme.headingBreakpoint}
              }
              h3#repodev {
                padding-top: 30px;
              }
              .container {
                ${theme.containerBreakpoint}
              }
              .projects-list {
                ${theme.projectListBreakpoint}
              }
            }
            @media screen and (max-width: 968px) {
              h3,
              h4 {
                ${theme.headingBreakpoint}
              }
              h3#repodev {
                padding-top: 30px;
              }
              .container {
                ${theme.containerBreakpoint}
              }
              .projects-list {
                ${theme.projectListBreakpoint}
                ::-webkit-scrollbar {
                  width: 0px; /* remove scrollbar space */
                  background: transparent; /* optional: just make scrollbar invisible */
                }
              }
            }

            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
              h3,
              h4 {
                ${theme.headingBreakpoint}
              }
              h3#repodev {
                padding-top: 30px;
              }
              .container {
                ${theme.containerBreakpoint}
              }
              .projects-list {
                ${theme.projectListBreakpoint}
              }
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
              h3,
              h4 {
                ${theme.headingBreakpoint}
              }
              h3#repodev {
                padding-top: 30px;
              }
              .container {
                ${theme.containerBreakpoint}
              }
              .projects-list {
                ${theme.projectListBreakpoint}
              }
            }
            .modal-content {
              border-radius: 0.45rem !important;
            }
          `}
        />
        <SEO />
        <NavBar />
        <div
          style={{
            position: 'relative',
            minHeight: '100vh',
          }}
        >
          {children}
          <Footer />
        </div>
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
