import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';

// import DarkModeToggle from './dark_mode_toggle';

const Wrapper = styled(Navbar)`
  margin-bottom: 0;
  background-color: #2d2d30 !important;
  border: 0;
  font-size: 11px !important;
  letter-spacing: 4px;
  ${'' /* opacity: 0.9; causing broken color in chrome v9x.x */}
  color: #d5d5d5;
`;

const MenuHover = styled(Link)`
  :hover {
    color: #ccff00 !important;
    text-decoration: none;
  }
`;

const Anchor = styled.a`
  :hover {
    color: #ccff00 !important;
    text-decoration: none;
  }
`;

const Brand = styled(Link)`
  padding-top: 0px;
`;

const NavBar = () => {
  const data = useStaticQuery(graphql`
    query Brand {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 50, height: 50)
        }
      }
    }
  `);

  return (
    <Wrapper fixed="top" expand="md" collapseOnSelect variant="dark">
      <Brand className="navbar-brand" to="/">
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          width="50"
          height="50"
          loading="eager"
          alt="Repodev"
          title="Repodev"
          style={{ display: 'block' }}
        />
      </Brand>
      <Navbar.Toggle aria-controls="myNavbar" />
      <Navbar.Collapse id="myNavbar">
        <Nav className="ml-auto nav navbar-nav navbar-right">
          <MenuHover to="/blog" role="tab" tabIndex="-1" className="nav-link">
            BLOG
          </MenuHover>
          <MenuHover
            to="/snippets"
            role="tab"
            tabIndex="-1"
            className="nav-link"
          >
            SNIPPETS
          </MenuHover>
          <MenuHover
            to="/snaggylabs"
            role="tab"
            tabIndex="-1"
            className="nav-link"
          >
            PROJECT
          </MenuHover>
          <Anchor href="/rss.xml" role="tab" tabIndex="-1" className="nav-link">
            RSS
          </Anchor>
          {/* set style={{ marginRight: '50px' }} at Anchor
          <DarkModeToggle toggle={toggler} mode={onMode} /> */}
        </Nav>
      </Navbar.Collapse>
    </Wrapper>
  );
};

export default NavBar;

// NavBar.propTypes = {
//   toggler: PropTypes.func.isRequired,
//   onMode: PropTypes.object.isRequired,
// };
