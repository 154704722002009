import React from 'react';
import PropTypes from 'prop-types';

export class ScrollButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalId: 0,
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
      <button
        title="Back to top"
        className="up-arrow"
        onClick={() => {
          this.scrollToTop();
        }}
        style={{ border: 'none', background: 'none', color: '#fff' }}
      >
        <i className="fas fa-angle-up"></i>{' '}
      </button>
    );
  }
}

ScrollButton.propTypes = {
  scrollStepInPx: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  delayInMs: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
